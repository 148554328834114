<template>
  <div>
    <b-alert
      v-height-fade.appear
      dismissible
      :variant="type"
      :show="duration"
      @dismissed="duration_ = 0"
      @dismiss-count-down="countDown"
    >
      <div class="alert-body" v-html="body"></div>
    </b-alert>
  </div>
</template>
<script>
import { BAlert } from "bootstrap-vue";
import { heightFade } from "@/@core/directives/animations";
export default {
  components: { BAlert },
  props: [
      'type',
      'body',
      'duration',
  ],
  directives: {
    "height-fade": heightFade,
  },
  methods: {
    countDown(duration) {
      this.duration_ = duration;
    },
  },
  data() {
      return {
          duration_: this.duration 
      }
  }
};
</script>
<style scoped></style>
